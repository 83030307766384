<div class="footer">
  <span> ©2022 Phoenix Process Equipment </span>
</div>

<style lang="scss">
  @import '../theme';

  span {
    color: rgb(255, 255, 255);
  }
  .footer {
    background-color: $color-black;
    text-align: center;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
</style>

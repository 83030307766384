<div class="header">
  <div class="content">
    <div class="logo">
      <a href="/">
        <img src="img/PHOENIX-White-Bird.png" alt="" class="header-logo" />
      </a>
    </div>    
  </div>
  <div class="clear" />
</div>

<style lang="scss">
  @import '../theme';
  .header {
    width: 100%;
    height: 100%;
    background-color: $color-primary-red;
    position: fixed;
    height: 65px;
    @include respond(phone-480px) {
      height:50px;
    }
  }

  a {
    text-decoration: none;
  }
  .content {
    margin: 0 auto;
    width: 95%;
    height: 100%;
    padding: 10px;
    @include respond(tab-port) {
      padding: 10px;
      width: 95%;
    }
    @include respond(phone) {
      padding: 10px;
      width: 100%;
    }
    @include respond(phone-600px) {
      padding: 12px;
      width: 100%;
    }
    @include respond(phone-480px) {
      padding: 10px;
      width: 100%;
    }
    @include respond(phone-320px) {
      padding: 10px;
      width: 100%;
    }
  }

  .logo {
    float: left;
  }
  .logo img {
    @include respond(phone) {
      width: 120px;
    }

    @include respond(phone-600) {
      width: 105px;
    }
    @include respond(phone-480px) {
      width: 80px;
    }
    @include respond(phone-320px) {
      width: 85px;
    }
  }

  
</style>

<script>
  export let H44;
  export let H45;
  export let H46;
  export let H47;
  export let H49;
  export let H51;
  export let H52;
  export let H54;
</script>

<div class="form">
  <div class="row">
    <div class="col">
      <label>sTPH:</label>
    </div>
    <div class="col">
      <input type="number" bind:value={H44} />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label>Current % SBW:</label>
    </div>
    <div class="col">
      <input type="number" bind:value={H45} />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label>Slurry GPM:</label>
    </div>
    <div class="col">
      <input type="number" bind:value={H46} />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label>Solids SG:</label>
    </div>
    <div class="col">
      <input type="number" bind:value={H47} />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <label>Needed % SBW:</label>
    </div>
    <div class="col">
      <input type="number" bind:value={H49} style="background-color:skyblue" />
    </div>
  </div>
  <hr />
  <div class="row answer">
    <div class="col">
      <label>Current Slurry SG:</label>
    </div>
    <div class="col">
      <input readonly="readonly" type="number" bind:value={H51} />
    </div>
  </div>
  <div class="row answer">
    <div class="col">
      <label>New Slurry SG:</label>
    </div>
    <div class="col">
      <input readonly="readonly" type="number" bind:value={H52} />
    </div>
  </div>

  <div class="row answer">
    <div class="col">
      <label><strong>ANSWER</strong></label>
    </div>
    <div class="col">
      <input readonly="readonly" type="number" bind:value={H54} />
    </div>
  </div>
</div>

<style lang="scss">
  i {
    position: absolute;
    margin-left: 5px;
    margin-top: 2px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
  }
  hr {
    margin: 20px;
  }
  input {
    width: 100%;
    border: 1px solid #ccc;
    padding: 5px;
  }
  div.form {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: floralwhite;
    opacity: 0.6;
    padding: 10px;
    margin: 12px 35px;
    text-align: left;
    & .row {
      margin: 1px;

      & .col {
        display: inline-block;
        width: 40%;
        padding: 4px 10px;
        text-align: right;
      }
    }
    & .answer input {
      background: gainsboro;
      font-weight: bold;
      color: #000;
    }
  }
  label {
    border: none;
    width: 40%;
  }
</style>

<script>
  export let D9;
  export let D10;
  export let D11;
  export let D13;
</script>

<div class="form">
  <div class="row">
    <div class="col">
      <label>Surry GPM: </label>
    </div>
    <div class="col">
      <input type="number" bind:value={D9} />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label>sTPH: </label>
    </div>
    <div class="col">
      <input type="number" bind:value={D10} />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label>Solids SG:</label>
    </div>
    <div class="col">
      <input type="number" bind:value={D11} />
    </div>
  </div>
  <hr />
  <div class="row answer">
    <div class="col">
      <label><strong>ANSWER</strong></label>
    </div>
    <div class="col" style="position:relative">
      <input readonly="readonly" type="number" bind:value={D13} />
      <i style="position: absolute; left: 50px; top: 10px; font-weight:bold"
        >%</i
      >
    </div>
  </div>
</div>

<style lang="scss">
  hr {
    margin: 20px;
  }
  i {
    position: absolute;
    margin-left: 5px;
    margin-top: 2px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
  }
  input {
    width: 100%;
    border: 1px solid #ccc;
    padding: 5px;
  }
  div.form {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    margin: 12px 35px;
    text-align: left;
    & .row {
      margin: 1px;

      & .col {
        display: inline-block;
        width: 40%;
        padding: 4px 10px;
        text-align: right;
      }
    }
    & .answer input {
      background: gainsboro;
      font-weight: bold;
      color: #000;
    }
  }
  label {
    border: none;
    width: 40%;
  }
</style>

<script>
  // import svelte component Header
  import Header from './Components/Header.svelte';
  import ContentArea from './Components/ContentArea.svelte';
  import Footer from './Components/Footer.svelte';
</script>

<Header />

<div class="container">
  <ContentArea />
</div>

<Footer />

<style lang="scss">
  @import './theme';

  .container {
    padding-top: $margin-huge;
    background-color: $color-gray-bg;
    width: 50%;
    margin: 0 auto;
    @include respond(tab-port) {
      width: 80%;
      margin: 0 auto;
    }
    @include respond(phone) {
      padding: 10px;
      width: 100%;
    }
    @include respond(phone-600px) {
      padding: 12px;
      width: 100%;
    }
    @include respond(phone-480px) {
      padding: 10px;
      width: 100%;
    }
    @include respond(phone-320px) {
      padding: 10px;
      width: 100%;
    }
  }
</style>

<script>
  export let D20;
  export let D21;
  export let D22;
  export let D24;
  export let D26;
</script>

<div class="form">
  <div class="row">
    <div class="col">
      <label>sTPH:</label>
    </div>
    <div class="col">
      <input type="number" bind:value={D20} />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label>% SBW:</label>
    </div>
    <div class="col">
      <input type="number" bind:value={D21} />
    </div>
  </div>

  <div class="row">
    <div class="col">
      <label>Solids SG:</label>
    </div>
    <div class="col">
      <input type="number" bind:value={D22} />
    </div>
  </div>
  <hr />
  <div class="row answer">
    <div class="col">
      <label>Slurry SG:</label>
    </div>
    <div class="col">
      <input readonly="readonly" type="number" bind:value={D24} />
    </div>
  </div>

  <div class="row answer">
    <div class="col">
      <label><strong>ANSWER (GPM)</strong></label>
    </div>
    <div class="col">
      <input readonly="readonly" type="number" bind:value={D26} />
    </div>
  </div>
</div>

<style lang="scss">
  hr {
    margin: 20px;
  }
  i {
    position: absolute;
    margin-left: 5px;
    margin-top: 2px;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
  }
  input {
    width: 100%;
    border: 1px solid #ccc;
    padding: 5px;
  }
  div.form {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    border: 1px solid #000;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    margin: 12px 35px;
    text-align: left;
    & .row {
      margin: 1px;

      & .col {
        display: inline-block;
        text-align: right;
        width: 40%;
        padding: 4px 10px;
      }
    }
    & .answer input {
      background: gainsboro;
      font-weight: bold;
      color: #000;
    }
  }
  label {
    border: none;
    width: 40%;
  }
</style>

<script>
  // import component HomeScreen
  import HomeScreen from './HomeScreen.svelte';
  import ScreenSGSBW from './ScreenSGSBW.svelte';
  import ScreenFlowsheetCalc from './ScreenFlowsheetCalc.svelte';
  export let ActiveScreen = 'Home';
  $: ActiveScreen = ActiveScreen;
</script>

{#if ActiveScreen === 'Home'}
  <HomeScreen bind:ActiveScreen />
{:else if ActiveScreen === 'SG&%SBW'}
  <ScreenSGSBW bind:ActiveScreen />
{:else if ActiveScreen === 'FLOW CALCS'}
  <ScreenFlowsheetCalc bind:ActiveScreen />
{/if}

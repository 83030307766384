<script>
  export let ActiveScreen;
  const handleButtonClick = (el) => {
    ActiveScreen = el.target.value;
    console.log(ActiveScreen);
  };
</script>

<div class="heading">
  <a href="/" class=""> <h1>PHOENIX Process Equipment</h1> </a>
</div>
<div class="clear" />

<div class="frm-main">
  <input type="button" class="btn-main" value="SG&%SBW" />
  <input
    type="button"
    class="btn-main"
    value="FLOW CALCS"
    on:click={handleButtonClick}
  />
  <input type="button" class="btn-main" value="Pipe Friction" />
  <input type="button" class="btn-main" value="Polymer System" />
</div>

<style lang="scss">
  @import '../theme';

  .heading {
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 20px;
    @include respond(phone) {
      margin-top: 50px;
    }
    @include respond(phone-600px) {
      padding: 15px;
    }
    @include respond(phone-320px) {
      padding: 8px;
    }
    @include respond(phone-270px) {
      padding: 5px;
    }
  }

  .heading a {
    text-decoration: none;
  }
  .heading h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    color: $color-primary-gray;
    @include respond(tab-port) {
      font-size: 30px;
      line-height: 50px;
    }
    @include respond(phone) {
      font-size: 30px;
      line-height: 37px;
    }
    @include respond(phone-600px) {
      font-size: 25px;
      line-height: 34px;
    }
    @include respond(phone-480px) {
      font-size: 20px;
      line-height: 25px;
    }
    @include respond(phone-320px) {
      font-size: 18px;
      line-height: 34px;
    }
    @include respond(phone-270px) {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .frm-main {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 80px;
    margin-top: 20px;
    @include respond(phone-600px) {
      margin-top: 15px;
    }
  }
  .btn-main {
    width: 95%;
    height: 60px;
    background-color: $color-white;
    border: 1px solid $color-primary-red;
    color: $color-primary-red;

    //box-shadow: 2px 2px 8px $color-primary-gray;
    font-family: $font-basic;
    font-size: 25px;
    transition: all 0.2s;
    @include respond(phone-600px) {
      height: 55px;
    }
    @include respond(phone-270px) {
      height: 40px;
    }
  }
  .btn-main:not(:last-child) {
    margin-bottom: $margin-big;
    @include respond(phone-600px) {
      margin-bottom: 25px;
    }
    @include respond(phone-320px) {
      margin-bottom: 20px;
    }
    @include respond(phone-270px) {
      margin-bottom: 18px;
    }
  }

  .btn-main:hover,
  :focus {
    background-color: $color-primary-red;
    color: $color-white;
    transform: translateY(-3px);
    border: none;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  .btn-main:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
</style>

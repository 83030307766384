<script>
  import SBWForm from './SBWForm.svelte';
  import GPMSlurryForm from './GPMSlurryForm.svelte';
  import GPMWaterForm from './GPMWaterForm.svelte';
  import GPMDiluteForm from './GPMDiluteForm.svelte';
  import FlowCalcSummary from './FlowCalcSummary.svelte';
  let b1 = true;
  let b2 = false;
  let b3 = false;
  let b4 = false;

  let D9 = 31;
  let D10 = 53;
  let D11 = 11;
  let D13 = 94.74;
  let D20 = 53;
  let D21 = 35;
  let D22 = 2.65;
  let D24 = 0;
  let D26 = 0;

  let D32 = 473.0;
  let D33 = 35;
  let D34 = 2.65;
  let D36 = 0;
  let D38 = 0;

  let D44 = 129.15;
  let D45 = 35;
  let D46 = 1154;
  let D47 = 2.64;
  let D49 = 25;
  let D51 = 0;
  let D52 = 0;
  let D54 = 0;

  let H44 = 53;
  let H45 = 35;
  let H46 = 473.1;
  let H47 = 2.65;
  let H49 = 35;
  let H51 = 0;
  let H52 = 0;
  let H54 = 0.0;

  $: D13 = (
    ((D10 * 2000) /
      60 /
      (D9 * 8.345 - (D10 * 2000) / 60 / D11 + (D10 * 2000) / 60)) *
    100
  ).toFixed(2);

  $: D24 = (1 / (D21 / 100 / D22 + (1 - D21 / 100))).toFixed(4);

  $: D26 = ((D20 * 2000) / (D21 / 100) / (8.345 * 60 * D24)).toFixed(1);

  $: D36 = (1 / (D33 / 100 / D34 + (1 - D33 / 100))).toFixed(4);

  $: D38 = (D32 * D36 * (1 - D33 / 100)).toFixed(1);

  $: D51 = (1 / (D45 / 100 / D47 + (1 - D45 / 100))).toFixed(4);

  $: D52 = (1 / (D49 / 100 / D47 + (1 - D49 / 100))).toFixed(4);

  $: D54 = ((D44 * 2000) / (D49 / 100) / (8.345 * 60 * D52) - D46).toFixed(1);

  $: H44 = D20;
  $: H45 = D21;
  $: H46 = D26;
  $: H47 = D22;
  $: H51 = (1 / (H45 / 100 / H47 + (1 - H45 / 100))).toFixed(4);
  $: H52 = (1 / (H49 / 100 / H47 + (1 - H49 / 100))).toFixed(4);
  $: H54 = ((H44 * 2000) / (H49 / 100) / (8.345 * 60 * H52) - H46).toFixed(1);

  const handleButtonClick = (buttonIndex) => {
    if (buttonIndex === 1) {
      b1 = !b1;
    }
    if (buttonIndex === 2) {
      b2 = !b2;
    }
    if (buttonIndex === 3) {
      b3 = !b3;
    }
    if (buttonIndex === 4) {
      b4 = !b4;
    }
  };
  const clearInputs = () => {
    // reset all inputs to 0
    D9 = null;
    D10 = null;
    D11 = null;
    D13 = null;
    D20 = null;
    D21 = null;
    D22 = null;
    D24 = null;
    D26 = null;
    D32 = null;
    D33 = null;
    D34 = null;
    D36 = null;
    D38 = null;
    D44 = null;
    D45 = null;
    D46 = null;
    D47 = null;
    D49 = null;
    D51 = null;
    D52 = null;
    D54 = null;
    H44 = null;
    H45 = null;
    H46 = null;
    H47 = null;
    H49 = null;
    H51 = null;
    H52 = null;
    H54 = null;
  };
</script>

<div class="app-screen">
  <div class="frm-main">
    <div class="heading">
      <a href="/" class=""> <h1>Flow Calcs</h1> </a>
    </div>

    <div class="clearInputs">
      <a href="/" on:click|preventDefault={clearInputs}>Clear All</a>
    </div>
    <div class="btn-step">
      <input
        type="button"
        class="btn-main"
        value="To Find the %SBW of a Slurry"
        on:click={() => {
          handleButtonClick(1);
        }}
      />
      <span
        on:click={() => {
          handleButtonClick(1);
        }}>{!b1 ? '⨁' : '⊖'}</span
      >
    </div>
    {#if b1}
      <div class="collapsible">
        <SBWForm bind:D9 bind:D10 bind:D11 bind:D13 />
      </div>
    {/if}
    <div class="btn-step">
      <input
        type="button"
        class="btn-main"
        value="To Find the GPM of Slurry"
        on:click={() => {
          handleButtonClick(2);
        }}
      />

      <span
        on:click={() => {
          handleButtonClick(2);
        }}>{!b2 ? '⨁' : '⊖'}</span
      >
    </div>
    {#if b2}
      <div class="collapsible">
        <GPMSlurryForm bind:D20 bind:D21 bind:D22 bind:D24 bind:D26 />
      </div>
    {/if}
    <div class="btn-step">
      <input
        type="button"
        class="btn-main"
        value="To Find the GPM of Water"
        on:click={() => {
          handleButtonClick(3);
        }}
      />
      <span
        on:click={() => {
          handleButtonClick(3);
        }}>{!b3 ? '⨁' : '⊖'}</span
      >
    </div>
    {#if b3}
      <div class="collapsible">
        <GPMWaterForm bind:D32 bind:D33 bind:D34 bind:D36 bind:D38 />
      </div>
    {/if}
    <div class="btn-step">
      <input
        type="button"
        class="btn-main"
        value="GPM of Water Needed to Dilute a Slurry"
        on:click={() => {
          handleButtonClick(4);
        }}
      />
      <span
        on:click={() => {
          handleButtonClick(4);
        }}>{!b4 ? '⨁' : '⊖'}</span
      >
    </div>
    {#if b4}
      <div class="collapsible">
        <GPMDiluteForm
          bind:D44
          bind:D45
          bind:D46
          bind:D47
          bind:D49
          bind:D51
          bind:D52
          bind:D54
        />
        <FlowCalcSummary
          bind:H44
          bind:H45
          bind:H46
          bind:H47
          bind:H49
          bind:H51
          bind:H52
          bind:H54
        />
      </div>
    {/if}
    <div class="clearInputs">
      <a href="/" on:click|preventDefault={clearInputs}>Clear All</a>
    </div>
  </div>
</div>

<style lang="scss">
  @import '../theme';
  .heading {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 15px;
    @include respond(phone) {
    }
    @include respond(phone-600px) {
      padding: 15px;
    }
    @include respond(phone-320px) {
      padding: 8px;
    }
    @include respond(phone-270px) {
      padding: 5px;
    }
  }
  .clearInputs {
    text-align: right;
    padding-right: 40px;
    font-size: 16px;
    a {
      text-decoration: none;
    }
  }

  .heading a {
    text-decoration: none;
  }
  .heading h1 {
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    color: $color-primary-gray;
    @include respond(tab-port) {
      font-size: 30px;
      line-height: 50px;
    }
    @include respond(phone) {
      font-size: 30px;
      line-height: 37px;
    }
    @include respond(phone-600px) {
      font-size: 25px;
      line-height: 34px;
    }
    @include respond(phone-480px) {
      font-size: 20px;
      line-height: 25px;
    }
    @include respond(phone-320px) {
      font-size: 18px;
      line-height: 34px;
    }
    @include respond(phone-270px) {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .btn-step {
    display: block;
    margin: 10px auto;
    & span {
      color: red;
      position: absolute;
      margin-top: 19px;
      margin-left: 5px;
      font-size: 20px;
    }
  }
  .app-screen {
    margin-top: 35px;
  }
  .frm-main {
    padding: 20px;
    margin: 45px auto;
    text-align: center;
    margin-top: 20px;
  }
  .btn-main {
    width: 96%;
    height: 60px;
    background-color: $color-white;
    border: 1px solid $color-primary-red;
    color: $color-primary-red;

    font-size: 14px;
    transition: all 0.2s;
  }
  .btn-main:not(:last-child) {
    text-align: left;
    padding-left: 22px;
  }
</style>
